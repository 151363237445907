import {
    BaseFieldData,
    FormAnswerFields,
    FormFields,
    FormFieldType, FormFileType,
    FrontendFormField,
} from '@/includes/types/Form.types'
import { IterableItemFactory } from '@/components/Triggers/iterable-item-factory'
import { IterableListItem } from '@/components/Triggers/types'

import { cloneDeep } from 'lodash'
import i18n from '@/i18n/i18n'

type FieldsStructMap = Record<FormFieldType, FrontendFormField>

const iterableItemFactory: IterableItemFactory = new IterableItemFactory()

const baseStruct:BaseFieldData = {
    name: '',
    description: '',
    required: true,
    property_id: null,
}

export function getFieldTemplate() {
    return iterableItemFactory.create(cloneDeep(formFieldsModel[FormFieldType.String]))
}

export function prepareFrontendFields(fields: Array<FormFields>): Array<IterableListItem<FrontendFormField>> {
    return fields.map(f => {
        const { id, ...fields } = f

        return {
            config: fields,
            model: formFieldsModel[f.type].model
        }
    })
        .map(iterableItemFactory.create)
}

export function prepareFormForCreate(fields: Array<IterableListItem<FrontendFormField>>): Array<Required<BaseFieldData>> {
    return fields.map((field, index) => ({
        id: index + 1,
        ...field.value.config
    }))
}

export function prepareFormForAnswer(fields: Array<IterableListItem<FrontendFormField>>): FormAnswerFields['values'] {
    const resObj = {}

    fields.forEach((field, index) => {
        const fieldModel = field.value.model
        const fieldConfig = field.value.config

        if (fieldConfig.required) {
            if (fieldModel === null || fieldModel === '') {
                throw i18n.t('field_form_required_error', [ fieldConfig.name ]);
            } else if (fieldConfig.type === FormFieldType.Multiselect && Array.isArray(fieldModel) && fieldModel.length === 0) {
                throw i18n.t('field_form_multiselect_empty_error', [ fieldConfig.name ])
            } else {
                resObj[index + 1] = fieldModel
            }
        } else {
            resObj[index + 1] = fieldModel
        }
    })

    return resObj
}

export const formFieldsModel: FieldsStructMap = {
    [FormFieldType.String]: {
        config: {
            type: FormFieldType.String,
            ...baseStruct,
            placeholder: '',
            validator: null,
        },
        model: ''
    },
    [FormFieldType.Number]: {
        config: {
            type: FormFieldType.Number,
            ...baseStruct,
        },
        model: 0
    },
    [FormFieldType.Double]: {
        config: {
            type: FormFieldType.Double,
            ...baseStruct,
        },
        model: 0.0
    },
    [FormFieldType.Date]: {
        config: {
            type: FormFieldType.Date,
            ...baseStruct,
            placeholder: ''
        },
        model: null
    },
    [FormFieldType.DateTime]: {
        config: {
            type: FormFieldType.DateTime,
            ...baseStruct,
            placeholder: ''
        },
        model: null
    },
    [FormFieldType.Multiselect]: {
        config: {
            type: FormFieldType.Multiselect,
            ...baseStruct,
            placeholder: '',
            options: [],
            limit: 2
        },
        model: []
    },
    [FormFieldType.Select]: {
        config: {
            type: FormFieldType.Select,
            ...baseStruct,
            placeholder: '',
            options: [],
        },
        model: 0
    },
    [FormFieldType.Files]: {
        config: {
            type: FormFieldType.Files,
            file_type: FormFileType.Media,
            ...baseStruct,
            limit: 1,
        },
        model: []
    },
}
