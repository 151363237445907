







































import { FormFields, FormFieldType } from '../../../includes/types/Form.types'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  data() {
    return {
      FormFieldType,
    }
  }
})
export default class FormFieldWrapper extends Vue {
  @Prop() config!: FormFields

  @Prop() value!: any
}
