











































import { FormService } from '@/includes/services/FormService'
import FullForm from '@/components/Forms/FullForm.vue'
import { prepareFormForAnswer, prepareFrontendFields } from '@/components/Forms/logic/FormsHelper'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { AnsweredUser } from '@/includes/types/Form.types'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { AxiosError } from 'axios'

const COUNTDOWN = 5000

@Component({
  components: {
    FullForm,
    CenteredColumnLayout,
    UserMainInfo
  },
  data() {
    return {
      COUNTDOWN
    }
  }
})
export default class FormPublic extends Vue {
  form:any = null

  frontendFields:any = null

  formCreated = false

  user: AnsweredUser | null = null

  boardKey: string | null = null

  get targetToUpload():any {
    return () => {
      return {
        target: 'target',
        target_id: this.boardKey
      }
    }
  }

  onCountdownFinish():void {
    setTimeout(() => {
      if (this.form.return_url) {
        window.location.href = this.form.return_url
      } else {
        window.close();
      }
    }, 1000);

  }

  async createAnswer():Promise<void> {
    try {
      const answer = prepareFormForAnswer(this.frontendFields)

      const { url } = await FormService.createFormAnswer('tg', {
        token: this.$route.params.formToken.toString(),
        answer: {
          values: answer
        },
      })
      
      successNotification()

      this.formCreated = true

      await setTimeout(() => {
        if (url) {
          window.location.href = url
        } else {
          window.close();
        }
      }, COUNTDOWN);

    } catch (e:any) {
      if (typeof e === 'object' && 'isAxiosError' in e) {
        const error = (e as AxiosError)

        if (error.response && error.response.status !== 403) {
          errorNotification(error)
        }
      }
      else {
        errorNotification(e)
      }
    }

  }

  created():void {
    FormService.getQuestionnaire('tg', {
      token: this.$route.params.formToken,
    })
      .then(({ form, user, board_key }) => {
        this.form = form
        this.user = user
        this.boardKey = board_key

        this.frontendFields = prepareFrontendFields(this.form.config.fields)
      })
  }
}
