<template>
  <a-row class="centered-column-layout">
    <a-col
      :xs="{span: 24, offset: 0}"
      :md="{span: 16, offset: 4}"
    >
      <slot/>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "CenteredColumnLayout"
}
</script>

<style lang="scss">

</style>